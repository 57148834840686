<!-- 选中城市时获取id -->
<template>
  <div class="cascaderArea">
    <el-select
      v-model="select.province"
      placeholder="请选择省份"
      @change="changeProvince"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.provinces"
        :key="item.id"
        :label="item.name"
        :value="item.name + '-' + item.id"
      ></el-option>
    </el-select>
    <el-select
      v-model="select.city"
      placeholder="请选择地区"
      @change="changeCity"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.citys"
        :key="item.id"
        :label="item.name"
        :value="item.name + '-' + item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getCityAll } from '@/api/common'
export default {
  props: {
    province: {
      type: String
    },
    city: {
      type: String
    }
  },
  data() {
    return {
      list: {
        provinces: [],
        citys: []
      },
      select: {
        province: '',
        provinceId: null,
        city: '',
        cityId: null
      }
    }
  },
  watch: {
    country(newVal) {
      if (newVal) {
        this.search()
      }
    }
  },
  async created() {
    await this.search()
  },
  methods: {
    async search() {
      this.list.provinces = sessionStorage.getItem('area')
      if (!this.list.provinces) {
        const { data: res } = await getCityAll()
        sessionStorage.setItem('area', JSON.stringify(res.data))
      }
      this.list.provinces = JSON.parse(sessionStorage.getItem('area'))
      if (this.province) {
        this.select.province = this.province
        this.changeProvince(this.province)
      }
      if (this.city) {
        this.select.city = this.city
        this.changeCity(this.city)
      }
    },
    changeProvince(val) {
      this.list.citys = []
      if (val != '') {
        let arr = val.split('-')
        this.list.provinces.forEach((item) => {
          if (item.name === arr[0]) {
            this.list.citys = item.cityResults
          }
        })
        this.provinceId = arr[1]
        this.$emit('searchProvince', arr[0], arr[1])
      } else {
        this.$emit('searchProvince', '', '0')
      }
      this.$emit('searchCity', '')
      this.select.city = ''
    },
    changeCity(val) {
      if (val != '') {
        let arr = val.split('-')
        this.$emit('searchCity', arr[0], arr[1])
      } else {
        this.$emit('searchCity', '', this.provinceId)
      }
    },
    clear() {
      this.select = {
        province: null,
        city: null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cascaderArea {
  .el-select {
    margin-right: 10px;
  }
}
</style>
